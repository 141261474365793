@import '../../style/variables';

.large-input {
  width: 300px !important;
}

.normal-input {
  width: 150px !important;
}

.medium-input {
  width: 230px !important;
}

.mat-input-element {
  color: black;
}

.mat-toolbar {
  .mat-mdc-form-field {
    .mat-mdc-form-field-label {
      color: gray;
    }
  }
}

mat-checkbox.small-toolbar-input,
mat-radio-group.small-toolbar-input {
  font-size: 15px;
  font-weight: 400;
}

mat-form-field {
  &.small-input {
    width: 80px !important;
  }

  &.title-input {
    float: right;

    .mat-input-infix {
      border: none;
    }
  }

  &.small-toolbar-input.mat-mdc-form-field {
    //height: 40px;
    font-size: 15px;
    margin-top: 5px;

    .mat-mdc-form-field-underline {
      background-color: rgb(0 0 0 / 22%);
    }

    &mat-select.mat-select {
      padding-top: 0;
    }
  }

  .mat-mdc-form-field-prefix {
    margin-right: 10px;
  }
}

.search-option {
  font-size: 14px;

  .address {
    color: gray;
    font-size: 13px;
    margin-left: 5px;
  }
}

.highlight {
  font-weight: bold;
  color: black;
}

.input-row {
  .mat-mdc-form-field,
  .mat-checkbox {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

mat-option.mat-mdc-option.with-description {
  min-height: 70px;
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 30px;

  .description {
    font-size: 13px;
    color: gray;
    line-height: 20px;
  }
}

.mat-mdc-option.mdc-list-item {
  line-height: 1.1;
  padding: 9px 10px;
}

mat-optgroup .mat-mdc-optgroup-label {
  background: $sc-primary; //lighten($sc-primary, 10);
  color: white;

  .mdc-list-item__primary-text {
    align-items: center !important;
    display: flex !important;
    flex-direction: row-reverse !important;
  }

  .mat-mdc-checkbox {
    margin-left: -11px;
  }
}

.mat-mdc-optgroup .mat-mdc-option:not(.mat-mdc-option-multiple) {
  padding-left: 16px !important;
}

.mat-input-element {
  &[type='date'],
  &[type='datetime'],
  &[type='datetime-local'],
  &[type='month'],
  &[type='week'],
  &[type='time'] {
    &::after {
      content: none !important;
    }
  }
}

.mdc-text-field--filled {
  background-color: transparent !important;
}

.mdc-text-field {
  padding: 0 !important;
}

.mat-mdc-form-field-infix {
  padding-top: 24px !important;
  padding-bottom: 7px !important;
}

.mat-mdc-text-field-wrapper:hover .mat-mdc-form-field-focus-overlay,
.mat-mdc-form-field-focus-overlay {
  opacity: 0 !important;
}

.appearance-fill {
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: #0000000a !important;
  }
  .mdc-text-field {
    padding: 0 16px !important;
  }
}
.mat-mdc-form-field-icon-prefix,
.mat-mdc-form-field-icon-prefix {
  align-self: baseline !important;
}
.mat-mdc-form-field-icon-suffix,
.mat-mdc-form-field-icon-suffix {
  align-self: end !important;
  display: inline-flex;
}

.mat-mdc-form-field-bottom-align {
  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    padding: 0;
  }
}

// Fix floating label to align with placeholder

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: calc(var(--mat-form-field-container-height) / 1.55) !important;
}

.mdc-text-field--filled .mdc-floating-label--float-above {
  transform: translateY(-138%) scale(0.75) !important;
}

// Fix arrow positioning in mat-select

.mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
  transform: none !important;
}
