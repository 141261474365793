/*** Global styles ***/
@use '@angular/material' as mat;

// see https://tailwindcss.com/docs/preflight
@tailwind base;
@tailwind components;
@tailwind utilities;

@import './theme';
@import './variables';

@import '@swimlane/ngx-datatable/index.scss';
@import '@swimlane/ngx-datatable/themes/material.scss';

@import './components/tables';
@import './components/grid';

@import './components/cards';
@import './components/chips';
@import './components/drag';
@import './components/padding-margin';
@import './components/buttons';
@import './components/toolbar';
@import './components/inputs';
@import './components/popup-menu';
@import './components/popover';
@import './components/colors';
@import './components/file-uploader';
@import './components/connectors';
@import './components/common-styles';
@import './components/slide-toggle';
@import './components/tabs';

:root {
  --mat-option-label-text-size: 14px;
  --mat-optgroup-label-text-size: 14px;
}

/**
Custom table classes
    no-click: no cursor on hover
    no-lines: no lines...
*/

* {
  font-family: $font;
}

.mat-mdc-tab-group.mat-primary .mat-link-bar,
.mat-tab-nav-bar.mat-primary .mat-link-bar {
  display: none !important;
}
.mat-mdc-tab-list {
  flex-grow: 0 !important;
  padding-bottom: $margin;
  padding-left: $margin;
}
.mat-mdc-tab-header {
  --mat-tab-header-label-text-tracking: 0;
}
.mat-mdc-tab,
.mat-mdc-tab-link {
  border-radius: $button-radius !important;
  overflow: hidden;
  min-width: 160px !important;
  margin-right: 8px;
}
.mat-mdc-tab-label.mdc-tab--active,
.mat-mdc-tab-link.mdc-tab--active,
.mdc-tab--active {
  background-color: white !important;
  border: 0 !important;
  border-radius: $button-radius !important;
  color: $sc-primary !important;
  font-weight: 600 !important;
  box-shadow: $button-shadow !important;
  opacity: 1;
  .mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
    border: 0 !important;
  }
}
a.mat-mdc-tab-link:hover,
.mat-mdc-tab:hover {
  color: $sc-primary !important;
  opacity: 1;
}
mat-datepicker-content.mat-datepicker-content {
  border-radius: $card-radius !important;
}
.mat-tab-nav-bar,
.mat-tab-header {
  border: none !important;
}
.mdc-dialog__surface {
  overflow: visible !important;
  border-radius: 1rem !important;
}
.mat-mdc-dialog-title {
  padding: 0 16px !important;
}
.mat-mdc-dialog-actions {
  padding: 16px 24px !important;
}
.dot-dot-dot::after {
  content: ' .';
  animation: dots 1s steps(5, end) infinite;
}

.medium-tooltip {
  font-size: 13px !important;
}

@keyframes dots {
  0%,
  20% {
    color: rgb(0 0 0 / 0%);
    text-shadow:
      0.25em 0 0 rgb(0 0 0 / 0%),
      0.5em 0 0 rgb(0 0 0 / 0%);
  }

  40% {
    color: black;
    text-shadow:
      0.25em 0 0 rgb(0 0 0 / 0%),
      0.5em 0 0 rgb(0 0 0 / 0%);
  }

  60% {
    text-shadow:
      0.25em 0 0 black,
      0.5em 0 0 rgb(0 0 0 / 0%);
  }

  80%,
  100% {
    text-shadow:
      0.25em 0 0 black,
      0.5em 0 0 black;
  }
}

.disabled-link {
  cursor: default;
}

.font-weight-normal {
  font-weight: normal;
}

.ngx-datatable.material {
  box-shadow: none;
}

.ngx-datatable.material {
  .datatable-body {
    .progress-linear {
      .container {
        width: 100% !important;
        max-width: none;
        background-color: #adc7a7;
        .bar {
          background-color: mat.m2-get-color-from-palette($primary);
        }
      }
    }
  }
}

.ngx-datatable.material .mat-form-field-label-wrapper {
  box-sizing: content-box !important;
}

.ngx-datatable.no-left-padding .datatable-body-row .datatable-body-cell:first-child {
  padding-left: 0;
}

.ngx-datatable .datatable-body {
  margin-bottom: -3px;
}

.fixed-heigh-table .datatable-body {
  max-height: 70vh;
  overflow-y: auto;
}

.page-count span.ng-star-inserted {
  display: none;
}

.ngx-datatable.material .datatable-header {
  height: 50px !important;
}

.ngx-datatable.material:not(.cell-selection, .no-click) .datatable-body-row:hover,
.ngx-datatable.material:not(.cell-selection, .no-click) .datatable-body-row:hover .datatable-row-group {
  cursor: pointer;
}

.ngx-datatable.with-input .datatable-body .datatable-body-row .datatable-body-cell .mat-form-field {
  margin-top: -0.9rem;
  margin-bottom: -0.9rem;
}

.ngx-datatable.material.single-selection .datatable-body-row.active,
.ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group,
.ngx-datatable.material.multi-selection .datatable-body-row.active,
.ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
  background-color: inherit !important;
}

.ngx-datatable.no-hover:not(.cell-selection) .datatable-body-row:hover,
.ngx-datatable.no-hover:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  cursor: auto;
  background-color: inherit;
}

.ngx-datatable:not(.no-lines) .datatable-body .datatable-row-wrapper:not(:first-child) .datatable-body-row {
  border-top: 1px solid rgb(235 235 235);
}

.ngx-datatable .datatable-body-cell-label button.mat-icon-button {
  height: 25px;
  width: 25px;
  line-height: 1;
}

.ngx-datatable.material .empty-row {
  line-height: 32px;
}

.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell.button-cell {
  padding-top: 9px;
  padding-bottom: 9px;
  vertical-align: middle;
}

.ngx-datatable.material .datatable-scroll,
.ngx-datatable.material .datatable-row-wrapper,
.ngx-datatable.material .datatable-body-row,
.ngx-datatable.material .datatable-row-center {
  //Will fix the issue where rows are not always 100% width of the table
  width: 100% !important;
}

.ngx-datatable.material .truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ngx-datatable.vertically-aligned .datatable-body .datatable-body-cell {
  display: flex;
  align-items: center;
}

body {
  margin: 0;
  font-family: $font;
  background: $body-background;
}

popover-content {
  position: absolute;

  & > div.popover.fade {
    display: none !important;
  }
}

mat-icon.mat-icon {
  &.online {
    line-height: inherit;
    font-size: 1.5em;
  }
}

mat-spinner.spinner-center.mat-mdc-progress-spinner {
  height: $spinner-size !important;
  width: $spinner-size !important;
  margin: auto;

  svg {
    height: $spinner-size !important;
    width: $spinner-size !important;
  }
}

mat-spinner.small-spinner.mat-mdc-progress-spinner {
  height: $spinner-size-sm !important;
  width: $spinner-size-sm !important;

  svg {
    height: $spinner-size-sm !important;
    width: $spinner-size-sm !important;
  }

  &.spinner-center {
    margin: auto;
  }
}

mat-spinner.spinner-size-text.mat-mdc-progress-spinner {
  //height: $spinner-size-text !important;
  //width: $spinner-size-text !important;
  display: inline;

  svg {
    height: $spinner-size-text !important;
    width: $spinner-size-text !important;
  }

  &.spinner-center {
    margin: auto;
  }
}

.text-w-icon {
  .mat-icon {
    font-size: 1.5rem;
    width: inherit;
    height: 1.7rem;
    vertical-align: middle;
  }
}

.notification2 {
  position: relative;
  height: 40px;
  width: 40px;
  text-align: center;

  span {
    position: absolute;
    top: 5px;
    right: 7px;
    width: 14px;
    height: 14px;
    line-height: 14px;
    background: $sc-primary;
    color: white;
    border-radius: 14px;
    font-size: 11px;
  }

  mat-icon.mat-icon {
    font-size: 24px;
    line-height: 30px;
    vertical-align: middle;
  }
}

.notification {
  position: absolute;
  top: 0;
  right: 0;
  height: 14px;
  width: 14px;
  background: $sc-primary;
  border-radius: 14px;
  color: white;
  font-size: 11px;
  line-height: 14px;
}

.update-point {
  span,
  mat-icon.mat-icon {
    color: $sc-primary;
  }
}

.info {
  height: auto;

  &.info-inline {
    p {
      margin-bottom: 0;
    }

    & > div {
      margin-right: 10px;
      display: inline-block;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .info-section {
    display: inline-block;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  label {
    color: rgb(0 0 0 / 54%);
    font-size: 12px;
    font-weight: 400;

    &:not(:first-child) {
      margin-top: 12px;
    }
  }

  p {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .info-row {
    display: block;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 0;
    -webkit-margin-end: 0;
    margin-top: 2px;
  }
}

button {
  &.mat-mdc-raised-button,
  &.mat-mdc-button {
    mat-spinner.button-spinner.mat-mdc-progress-spinner {
      height: $spinner-size-sm !important;
      width: $spinner-size-sm !important;
      margin: auto;
      margin-top: 7px;
      margin-bottom: 7px;

      svg {
        height: $spinner-size-sm !important;
        width: $spinner-size-sm !important;
      }
    }
  }

  &[mat-icon-button] {
    mat-spinner.button-spinner.mat-mdc-progress-spinner {
      height: 60% !important;
      width: 60% !important;
      margin: auto;

      svg {
        height: 100% !important;
        width: 100% !important;
      }
    }
  }
}
.full-width {
  width: 100%;
}
.hover-text {
  //text-decoration: underline dotted;
  border-bottom: dotted 1px gray;
  padding-bottom: 1px;
}

.label {
  color: rgb(0 0 0 / 54%);
  margin: 0;
  font-family: $font;
  font-size: 14px;
  font-weight: 400;
}

.loader {
  padding: 6px 0;

  mat-spinner.mat-mdc-progress-spinner {
    height: 40px !important;
    width: 40px !important;
    margin: auto;

    svg {
      height: 40px !important;
      width: 40px !important;
    }
  }
}

mat-icon.help-icon {
  color: gray;
  cursor: pointer;
  vertical-align: bottom;
  font-size: 21px !important;
}

.inline-block {
  display: inline-block !important;
}

.align-middle {
  vertical-align: middle !important;
}

.icon-link,
.icon-inline {
  font-size: 1em;
  line-height: 1.2em;
  vertical-align: middle;

  mat-icon.mat-icon {
    font-size: 1.5em;
    line-height: inherit;
    vertical-align: middle;
  }

  span {
    vertical-align: middle;
  }
}

a.table-icon-link,
.icon-inline-table {
  @extend .icon-link;

  mat-icon.mat-icon {
    line-height: 1em;
  }
}

.txt-center {
  text-align: center !important;
}

.show-l {
  @media screen and (max-width: 400px) {
    display: none;
  }
}

.grid-align-top {
  padding-bottom: 0;

  .mat-grid-tile .mat-figure {
    align-items: flex-start;
  }
}

.grid-align-center {
  padding-bottom: 0;

  .mat-grid-tile .mat-figure {
    align-items: center;
  }
}

.page-content {
  padding: 20px;
}

.flex {
  display: flex;
}

.flex-center {
  @extend .flex;

  align-items: center;
}

.flex-right {
  margin-left: auto;
}

.m-width {
  max-width: 250px;
}

.right {
  float: right;
}

.left {
  float: left;
}

.info-list {
  label {
    /*
        color:gray;
        font-size: 0.8em; */
    color: rgb(0 0 0 / 54%);
    font-size: 12px;
    font-weight: 400;
  }

  p {
    margin-top: 2px;
  }
}

/* Table */
table.table-primary {
  th {
    color: gray;
    font-weight: 300;
    text-align: left;
  }

  th,
  td {
    padding: 0 10px 5px 0;
  }

  .mat-icon-button {
    height: 30px;
    width: 30px;
    line-height: 30px;
  }

  .mat-icon {
    font-size: 17px;
  }

  .button-cell {
    padding-top: 20px;
  }
}

.center-80 {
  max-width: 80%;
  margin: auto;
}

.center-50 {
  max-width: 50%;
  margin: auto;
}

.no-show {
  display: none !important;
}

.how-to.mat-list {
  max-width: 400px;

  h3 {
    margin-bottom: 20px;
  }

  .mat-list-item p.mat-line {
    margin-bottom: 5px;
    line-height: 20px;

    span:last-child {
      width: 250px;
      margin-left: 10px;
      word-wrap: normal;
      white-space: pre-wrap;
    }
  }

  &.mat-list .mat-list-item p.mat-line {
    span {
      float: left;

      &:first-child {
        width: 100px;
        color: gray;
        font-size: 0.9em;
      }
    }
  }
}

.link-style {
  color: #30a5c7;
  &:hover {
    cursor: pointer;
    color: #1d6479;
  }
}

.mat-select-panel,
.mat-autocomplete-panel {
  max-height: 320px !important;
}
.ellipsis-table-col .datatable-body-cell-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
